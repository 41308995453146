import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 style={{ marginLeft: "48px", marginRight: "48px" }}>
          Lunaruu! Gets you off your feet and baby to sleep!
        </h1>
        <h2 style={{ marginLeft: "48px", marginRight: "48px" }}>
          Coming Soon to a Nursery near You!
        </h2>
      </header>
    </div>
  );
}

export default App;
